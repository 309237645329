import { useState } from 'react'

const ProjectsCategories = ({categories}) => {
    const [activeCategory, setActiveCategory] = useState();

    const changeCategoryHandler = (activeCat) => {
        setActiveCategory(activeCat);
    }

  return 
}

export default ProjectsCategories