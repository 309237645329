import {AiOutlineLinkedin} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'




const data = [
    {id:1, link: 'https://www.linkedin.com/in/iheanyiekweghariri/', icon: <AiOutlineLinkedin/>},
    {id:2, link: 'https://github.com/ijekwe', icon: <AiFillGithub/>},   
]

export default data