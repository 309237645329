
const data = [
    { id: 1, link:'#', title: 'Home' },
    { id: 2, link:'#about', title: 'About'},
    { id: 3, link:'#services', title: 'Services'},
    { id: 4, link:'#portfolio', title: 'Experiences'},
    { id: 5, link:'#contact', title: 'Contact'},

]

export default data;